import h from '../../lib/react-hyperscript';
import { fmt } from '../helper';
import * as _ from 'ramda';
import { QuickFilterBar, QuickFilterSpec } from './QuickFilterBar';
import ClientAutoComplete from './ClientAutoComplete';
import { Button, Stack, Box, lighten } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import {
  DataGridPro as DataGrid,
  DataGridProProps,
  GridColDef,
  GridToolbar,
  useGridApiRef,
  GridInitialState,
  GridSortDirection,
  GridFilterModel,
  GridFilterItem,
  GridLogicOperator,
  GridSortModel,
  GridColumnVisibilityModel,
  GridColumnGroupingModel,
  GridPinnedColumns,
  GridRowClassNameParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import { assertIsDefined } from '../util';
import { useBaseData } from '../base_data';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

const headerClassName = 'tiq-grid-naming-group';

export const format = {
  dollars: ({ value }: { value: number | null }) => fmt.dollars(value),
  datetime: ({ value }: { value: string }) => fmt.datetime(value),
  date: ({ value }: { value: string }) => fmt.date(value),
  percent: ({ value }: { value: number }) => fmt.percent(value),
  percentWithTwoDecimals: ({ value }: { value: number }) =>
    fmt.percentWithTwoDecimals(value),
  yesno: ({ value }: { value: boolean | null }) => (value ? 'Yes' : 'No'),
  dayOfWeek: ({ value }: { value: string }) => fmt.dayOfWeek(value),
};

const orderedColumns = (state: GridInitialState) => {
  assertIsDefined(state.columns?.orderedFields);
  return _.filter(
    (f) => state.columns?.columnVisibilityModel?.[f] !== false,
    state.columns?.orderedFields ?? [],
  );
};

const columnsVisibilityModel = (
  visible_columns: string[],
  all_columns: string[],
) => {
  const hidden_columns = _.difference(all_columns, visible_columns);
  return _.fromPairs(_.map((c) => [c, false], hidden_columns));
};

const stateToSearchParams = (
  gridState: GridInitialState,
  all_columns: string[],
) => {
  const params: any = {};

  for (const { field, sort } of gridState.sorting?.sortModel ?? []) {
    params['sort.' + field] = sort;
  }

  let i = 1;
  for (const filter of gridState.filter?.filterModel?.items ?? []) {
    //    if (!filter.value) continue;
    params['filter.' + i.toString().padStart(2, '0') + '.' + filter.field] = `${
      filter.operator
    },${filter.value ?? ''}`;
    i += 1;
  }

  if (gridState.filter?.filterModel?.items?.length) {
    params['filterOp'] = gridState.filter?.filterModel.logicOperator ?? 'and';
  }

  if (gridState.columns?.orderedFields) {
    const cols = orderedColumns(gridState);
    if (_.difference(all_columns, cols).length) {
      params.columns = cols;
    }
  }

  if (_.keys(params).length) {
    params.v = QUERY_FORMAT_VERSION;
  }

  const q = new URLSearchParams(params);
  return q;
};

const searchParamsToState = (p: URLSearchParams, all_columns: string[]) => {
  if (p.get('v') !== QUERY_FORMAT_VERSION) {
    p = new URLSearchParams();
  }
  const gridState = _.reduce(
    (r, param) => {
      if (param[0] === 'columns') {
        const visible_columns = param[1].split(',');
        assertIsDefined(r.columns);
        r.columns.columnVisibilityModel = columnsVisibilityModel(
          visible_columns,
          all_columns,
        );
      }
      if (param[0].startsWith('sort.')) {
        const field = param[0].slice(5);
        const dir = param[1];
        assertIsDefined(r.sorting?.sortModel);
        r.sorting.sortModel.push({ field, sort: dir as GridSortDirection });
      }
      if (param[0].startsWith('filter.')) {
        // There is a number that also must be sliced to disambiguate multiple filters
        // on same field
        const field = param[0].slice(10);
        const id = parseInt(param[0].slice(8, 10), 10);
        // const [operatorValue, value] = param[1].split(',') is not correct for the case "isAnyOf"
        // since the value should be an array for this case
        const [operator] = param[1].split(',');
        const value = param[1].split(',').splice(1);
        assertIsDefined(r.filter?.filterModel);
        r.filter.filterModel.items.push({
          id,
          field,
          operator,
          value: operator === 'isAnyOf' ? value : value.join(','),
        });
      }
      if (param[0] === 'filterOp') {
        assertIsDefined(r.filter?.filterModel);
        r.filter.filterModel.logicOperator = param[1] as GridLogicOperator;
      }
      return r;
    },
    {
      columns: { columnVisibilityModel: {}, orderedFields: all_columns },
      sorting: { sortModel: [] },
      filter: { filterModel: { items: [] } },
    } as GridInitialState,
    Array.from(p.entries()),
  );

  return gridState;
};

const QUERY_FORMAT_VERSION = '2';

const clientIdFromModel = (f: GridFilterModel) => {
  const filterItem = _.find((item) => item.field === 'client_id', f.items);
  return filterItem ? parseInt(filterItem.value, 10) : null;
};

export type QueryDataGridProps = {
  rows: any[];
  column_specs: GridColDef[];
  filter_specs?: QuickFilterSpec[];
  column_groups?: GridColumnGroupingModel;
  add_button?: boolean;
  query: URLSearchParams;
  onQueryChange: (query: URLSearchParams) => void;
  pinnedColumns: GridPinnedColumns;
  onPinnedColumnsChange: (cols: GridPinnedColumns) => void;
  getRowClassName?: (
    params: GridRowClassNameParams<GridValidRowModel>,
  ) => string;
  sortModel?: GridSortModel;
  hide_client_filter?: boolean;
  getRowId?: (row: GridValidRowModel) => number | string;
  initialState?: GridInitialStatePro;
  filterRightComponent?: React.ReactNode;
  onCellClick?: DataGridProProps['onCellClick'];
};

export function QueryDataGrid({
  rows,
  column_specs,
  filter_specs,
  column_groups,
  add_button,
  query,
  onQueryChange,
  pinnedColumns,
  onPinnedColumnsChange,
  getRowClassName,
  sortModel,
  hide_client_filter,
  getRowId,
  initialState,
  filterRightComponent,
  onCellClick,
}: QueryDataGridProps) {
  column_groups =
    column_groups &&
    _.map(
      (cg) => _.assoc('headerClassName', headerClassName, cg),
      column_groups,
    );

  const all_columns = _.map((c) => c.field, column_specs);

  const {
    baseData: { getClients },
  } = useBaseData();

  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const gridState = searchParamsToState(query, all_columns);
  assertIsDefined(gridState.filter?.filterModel);
  assertIsDefined(gridState.columns?.columnVisibilityModel);

  const handleStateChange = (state: GridInitialState) => {
    const newQuery = stateToSearchParams(state, all_columns);
    onQueryChange(newQuery);
  };

  const handleFilterBarChange = (items: GridFilterItem[]) => {
    const state = apiRef.current.exportState();
    const newFilterModel = _.assocPath(
      ['items'],
      items,
      state.filter?.filterModel || {},
    );
    handleFilterChange(newFilterModel as GridFilterModel);
  };

  const handleFilterChange = (filterModel: GridFilterModel) => {
    handleStateChange({
      ...gridState,
      filter: { filterModel },
    });
  };

  const handleSortChange = (sortModel: GridSortModel) => {
    handleStateChange({
      ...gridState,
      sorting: { sortModel },
    });
  };

  const handleColumnVisibilityChange = (
    columnVisibilityModel: GridColumnVisibilityModel,
  ) => {
    handleStateChange({
      ...gridState,
      columns: { columnVisibilityModel, orderedFields: all_columns },
    });
  };

  const handleClientIdChange = (id: number) => {
    handleFilterChange({
      items: id
        ? [
            {
              id: 1,
              field: 'client_id',
              operator: 'equals',
              value: id.toString(),
            },
          ]
        : [],
      logicOperator: GridLogicOperator.And,
    });
  };

  const client_id = clientIdFromModel(gridState.filter.filterModel);

  return h(Stack, { flexGrow: 1, direction: 'column' }, [
    h(Stack, { direction: 'row', justifyContent: 'space-between' }, [
      h(Stack, { direction: 'row', alignItems: 'top' }, [
        h(
          Box,
          { sx: { display: 'inline-block', verticalAlign: 'middle', mb: 2 } },
          [
            !hide_client_filter
              ? h(ClientAutoComplete, {
                  clients: getClients(),
                  client_id,
                  id: 'client_id',
                  name: 'client_text_input',
                  label: 'Client',
                  onChange: (client) =>
                    handleClientIdChange(client?.id ?? null),
                  sx: { width: '300px', fontSize: '10px' },
                  fontSize: '12px',
                })
              : null,
          ],
        ),
        h(
          Box,
          {
            sx: { display: 'inline-block', verticalAlign: 'top', mb: 2 },
          },
          [
            filter_specs
              ? h(QuickFilterBar, {
                  specs: filter_specs,
                  filterItems: gridState.filter.filterModel.items,
                  onChange: handleFilterBarChange,
                })
              : null,
          ],
        ),
        h(Box, { sx: { flexShrink: 100 } }, [
          filterRightComponent ? filterRightComponent : null,
        ]),
      ]),
      add_button
        ? h(
            Button,
            {
              variant: 'contained',
              onClick: () => navigate('add', { relative: 'path' }),
              sx: {
                height: '50px',
                width: '100px',
                whiteSpace: 'nowrap',
                flexShrink: 0,
              },
            },
            'Add',
          )
        : null,
    ]),

    h(
      Stack,
      {
        direction: 'column',
        flexGrow: 1,
        sx: {
          // for some reason, if this height variable is not included, the grid attempts
          // to expend to a height to include all the rows
          height: '500px',
          backgroundColor: '#fff',
          '& .tiq-grid-naming-group': {
            boxSizing: 'border-box',
            backgroundColor: '#eee',
            borderRight: '2px solid',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          },
          '& .warning': {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.6),

            '&:hover': {
              bgcolor: (theme) =>
                `${lighten(theme.palette.warning.main, 0.5)}!important`,
            },
          },
          '& .success': {
            bgcolor: (theme) => lighten(theme.palette.success.main, 0.6),

            '&:hover': {
              bgcolor: (theme) =>
                `${lighten(theme.palette.success.main, 0.5)}!important`,
            },
          },
          '& .secondary': {
            bgcolor: () => lighten('#000', 0.7),

            '&:secondary': {
              bgcolor: () => `${lighten('#000', 0.6)}!important`,
            },
          },
        },
      },

      [
        h(DataGrid, {
          sx: {
            '& .MuiDataGrid-row.Mui-hovered': {
              backgroundColor: 'aliceblue',
            },
            '& .even-dg-row': {
              backgroundColor: 'rgb(245, 245, 245)',
            },
          },
          rows,
          columns: column_specs,
          paginationModel: { page: 1, pageSize: 100 },
          disableRowSelectionOnClick: true,
          rowHeight: 34,
          components: {
            Toolbar: GridToolbar,
            // ColumnsPanel: (e) => {
            //   console.log(e);
            //   return h('div', {}, ['test content']);
            // },
          },
          apiRef,
          //          initialState: gridState,
          //onStateChange: gridStateChange,
          experimentalFeatures: { columnGrouping: true },
          columnGroupingModel: column_groups,
          filterModel: gridState.filter.filterModel,
          sortModel: gridState.sorting?.sortModel?.length
            ? gridState.sorting?.sortModel
            : sortModel,
          columnVisibilityModel: gridState.columns.columnVisibilityModel,
          // localstorage state can end up undefined
          initialState: {
            pinnedColumns: { left: ['id'], right: ['DELETE', 'BROADCAST'] },
            ...initialState,
          },
          pinnedColumns: pinnedColumns,
          onColumnVisibilityModelChange: handleColumnVisibilityChange,
          onFilterModelChange: handleFilterChange,
          onSortModelChange: handleSortChange,
          onPinnedColumnsChange,
          getRowClassName: getRowClassName
            ? getRowClassName
            : (params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? 'even-dg-row'
                  : 'odd-dg-row',
          getRowId: getRowId ? getRowId : (row) => row.id ?? row.client_id,
          onColumnResize: (_params, event, _details) => {
            const ele = document.getElementsByClassName(
              'MuiDataGrid-virtualScroller',
            )[0];
            if (!!ele && event.clientX >= ele.clientWidth) ele.scrollLeft += 5;
          },
          onCellClick,
        }),
      ],
    ),
  ]);
}
