import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import DataGridEntryLink from '../../../common/components/DataGridEntryLink';
import { LeagueId } from '../../../common/types';

const daysPerSaleGetter =
  (days: number) =>
  ({ row }: { row: any }) => {
    if (
      !row.daily_listed_counts ||
      !row.daily_sold_counts ||
      row.daily_listed_counts.length < days
    ) {
      return null;
    }
    const listed = row.daily_listed_counts
      .slice(0, days)
      .reduce((acc: number, x: number) => acc + x, 0);
    const sold = row.daily_sold_counts
      .slice(0, days)
      .reduce((acc: number, x: number) => acc + x, 0);
    console.log(
      row.id,
      listed,
      sold,
      row.daily_listed_counts,
      row.daily_sold_counts,
    );
    if (sold === 0) {
      return listed === 0 ? null : Infinity;
    }
    return Math.round(listed / sold);
  };

const column_specs: GridColDef[] = [
  {
    field: 'client_id',
    align: 'center',
    width: 80,
  },
  {
    field: 'client_name',
    headerName: 'name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: ({ row }) => row.client_name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'package_id',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/pricing/inventory?filter.01.package_id=equals%2C${params.row.id}&v=2`,
      ),
  },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
  { field: 'league', width: 75 },
  { field: 'season', width: 75 },
  {
    field: 'team',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.team },
        `/pricing/packages?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
    minWidth: 50,
    maxWidth: 75,
  },
  {
    field: 'amp',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'last_adjustment',
    width: 160,
    type: 'dateTime',
    valueFormatter: format.datetime,
  },
  {
    field: 'is_shown',
    width: 75,
    type: 'boolean',
  },

  {
    field: 'unlisted',
    align: 'right',
    type: 'number',
  },
  {
    field: 'listed+sold',
    valueGetter: ({ row }) => row.listed + row.sold,
    align: 'right',
    type: 'number',
  },
  {
    field: 'sold',
    align: 'right',
    type: 'number',
  },
  {
    field: 'delisted',
    align: 'right',
    type: 'number',
  },

  {
    field: 'dps_30',
    align: 'right',
    valueGetter: daysPerSaleGetter(30),
    type: 'number',
  },
  {
    field: 'dps_14',
    align: 'right',
    valueGetter: daysPerSaleGetter(14),
    type: 'number',
  },
  {
    field: 'dps_7',
    align: 'right',
    valueGetter: daysPerSaleGetter(7),
    type: 'number',
  },

  {
    field: 'listed_days_without_sale_total',
    headerName: 'ldws_total',
    align: 'right',
    type: 'number',
  },
  {
    field: 'listed_days_without_sale_lt80',
    headerName: 'ldws_80-',
    align: 'right',
    type: 'number',
  },
  {
    field: 'listed_days_without_sale_gte80',
    headerName: 'ldws_80+',
    align: 'right',
    type: 'number',
  },

  {
    field: 'sell_thru_14_days_total',
    headerName: '14dst_total',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
  },
  {
    field: 'sell_thru_14_days_lt80',
    headerName: '14dst_80-',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
  },
  {
    field: 'sell_thru_14_days_gte80',
    headerName: '14dst_80+',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
  },

  {
    field: 'listed_under_30',
    align: 'right',
    type: 'number',
  },
  {
    field: 'listed_under_7',
    align: 'right',
    type: 'number',
  },
  {
    field: 'unsold_model_diff_avg',
    headerName: 'unsold_modelΔ',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'sold_model_diff_avg',
    headerName: 'sold_modelΔ',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },

  {
    field: 'listed_value',
    headerName: '$listed',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sold_value',
    headerName: '$sold',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'unlisted_value',
    headerName: '$unlisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'delisted_value',
    headerName: '$delisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },

  {
    field: 'list_change_avg',
    headerName: 'list±',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
  },
];

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Client',
    children: [{ field: 'client_id' }, { field: 'client_name' }],
  },

  {
    groupId: 'Package Details',
    children: [
      { field: 'package_id' },
      { field: 'season' },
      { field: 'is_playoff' },
      { field: 'league' },
      { field: 'team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'amp' },
      { field: 'last_adjustment' },
      { field: 'is_shown' },
    ],
  },

  {
    groupId: 'Listed days per sale (DTE weighted)',
    children: [{ field: 'dps_30' }, { field: 'dps_14' }, { field: 'dps_7' }],
  },
  {
    groupId: 'Listed days w/o sale',
    children: [
      { field: 'listed_days_without_sale_total' },
      { field: 'listed_days_without_sale_lt80' },
      { field: 'listed_days_without_sale_gte80' },
    ],
  },
  {
    groupId: '14 day sell thru',
    children: [
      { field: 'sell_thru_14_days_total' },
      { field: 'sell_thru_14_days_lt80' },
      { field: 'sell_thru_14_days_gte80' },
    ],
  },

  {
    groupId: 'Stats',
    children: [
      { field: 'unlisted' },
      { field: 'listed+sold' },
      { field: 'sold' },
      { field: 'delisted' },
      { field: 'listed_under_30' },
      { field: 'listed_under_7' },
      { field: 'unsold_model_diff_avg' },
      { field: 'sold_model_diff_avg' },
      { field: 'sold_value' },
      { field: 'unlisted_value' },
      { field: 'delisted_value' },
      { field: 'listed_value' },
      { field: 'list_change_avg' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
    { id: 'amp', label: 'AMP?', yesNoAny: true },
  ];
  return filter_specs;
};

const hydrateRows = (
  baseData: BaseDataProvider,
  rows: { client_id: number; team_id: number }[],
) => {
  return rows.map((row) => {
    const client = baseData.getClient(row.client_id);
    const hteam = baseData.getTeam(row.team_id);
    return {
      ...row,
      client_name: client?.first_name + ' ' + client?.last_name,
      team: hteam?.name_short,
    };
  });
};

export default function PackagePricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = hydrateRows(baseData, rows);
  return PageDataGrid({
    id: 'ticket_package_pricing',
    rows: _.filter((r) => r.is_client || baseData.include_test_data, rows),
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
