import h from '../../lib/react-hyperscript';
import * as _ from 'ramda';
import { QueryDataGrid, QueryDataGridProps } from './QueryDataGrid';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'ts-debounce';
import { useLocalStorage } from 'react-use';
import { GridPinnedColumns } from '@mui/x-data-grid-pro';
import { assertIsDefined } from '../util';

const useSearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const saveQuery = (id: string, q: URLSearchParams) => {
  window.localStorage.setItem(
    `${id}-query-v2`,
    JSON.stringify(Array.from(q.entries())),
  );
};

const loadQuery = (id: string) => {
  const entries = JSON.parse(
    window.localStorage.getItem(`${id}-query-v2`) || '[]',
  );
  return new URLSearchParams(entries);
};

const replaceSearchParams = debounce((q: URLSearchParams) => {
  const searchStr = q.toString();
  window.history.replaceState(
    null,
    '',
    window.location.pathname + (_.isEmpty(searchStr) ? '' : '?' + searchStr),
  );
}, 200);

export type PageDataGridProps = { id: string; data_grid_id?: string } & Omit<
  QueryDataGridProps,
  'query' | 'onQueryChange' | 'pinnedColumns' | 'onPinnedColumnsChange'
>;

// The handling of pinned columns is a bit complicated.
//
// Unlike the other configurations of a data grid report, your last used pinned columns become
// your default for future reports. These defaults are overriden, AND updated, if a report
// URL contains pinned columns.
//
// Their is a practical reason for this. Pinning columns can be easier than hiding columns,
// so it is more ceonvenient for creating reports. Plus having other columns available, just not
// pinned, makes the reports more powerful.
export function PageDataGrid({
  id,
  rows,
  column_specs,
  filter_specs,
  column_groups,
  add_button,
  getRowClassName,
  sortModel,
  hide_client_filter,
  data_grid_id = 'id',
  getRowId,
  filterRightComponent,
  initialState,
  onCellClick,
}: PageDataGridProps) {
  const [query, setQuery] = useState(new URLSearchParams());
  const nav = useNavigate();
  const navigateQuery = useCallback(
    debounce((q: URLSearchParams) => {
      nav('?' + q);
    }, 500),
    [nav],
  );

  let initial_query = useSearchParams();
  if ([...initial_query.keys()][0] === 'last') {
    initial_query = loadQuery(id);
    replaceSearchParams(initial_query);
  }

  const addPinnedToQuery = (
    pcols: GridPinnedColumns,
    query: URLSearchParams,
  ) => {
    const q = new URLSearchParams(query);
    if (pcols.left?.length) q.set('pinned.left', pcols.left.join(','));
    if (pcols.right?.length) q.set('pinned.right', pcols.right.join(','));
    return q;
  };

  const handleQueryChange = (q: URLSearchParams) => {
    if (pinnedColumns) q = addPinnedToQuery(pinnedColumns, q);
    setQuery(q);
    navigateQuery(q);
  };

  const handlePinnedColumnsChange = (pcols: GridPinnedColumns) => {
    const q = addPinnedToQuery(pcols, query);
    setQuery(q);
    navigateQuery(q);
    savePinnedColumns(pinnedColumns);
  };

  useEffect(() => {
    //if the query has pinned columns, update our saved pinned columns,
    //and leave the query as is
    const left = initial_query.get('pinned.left')?.split(',');
    const right = initial_query.get('pinned.right')?.split(',');
    if (left || right) {
      savePinnedColumns({ left: left ?? [], right: right ?? [] });
    }

    saveQuery(id, initial_query);
    setQuery(initial_query);
  }, [initial_query.toString()]);

  const [pinnedColumns, savePinnedColumns] = useLocalStorage(
    `${id}-grid-column-pinning`,
    {} as GridPinnedColumns,
  );

  const modifiedPins: GridPinnedColumns = {};
  if (pinnedColumns) {
    const left = pinnedColumns?.left ?? [];
    const right = pinnedColumns?.right ?? [];

    modifiedPins.left = left.includes(data_grid_id)
      ? left
      : [data_grid_id, ...left];

    let right_arr = [];
    if (!right.includes('BROADCAST')) {
      right_arr.push('BROADCAST');
    }
    if (!right.includes('DELETE')) {
      right_arr.push('DELETE');
    }
    modifiedPins.right = [...right_arr, ...right];
  }

  return h(QueryDataGrid, {
    query,
    onQueryChange: handleQueryChange,
    pinnedColumns: modifiedPins,
    onPinnedColumnsChange: handlePinnedColumnsChange,
    rows,
    column_specs,
    filter_specs,
    column_groups,
    add_button,
    getRowClassName,
    sortModel,
    hide_client_filter,
    getRowId,
    filterRightComponent,
    initialState,
    onCellClick,
  });
}
