import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import { getActionColumnDef } from '../../../common/components/DataGridActionColumn';
import { broadcastInventory, unbroadcastInventory } from '../../../common/api';

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    width: 120,
    resizable: false,
    hideable: false,
    pinnable: false,
    renderCell: (params) =>
      DataGridEntryButton(
        params,
        `https://skybox.vividseats.com/inventory?ef.keywords="${params.row.event_name}"&inf.section=${params.row.section}&inf.row=${params.row.row}`,
        params.row.is_playoff ? { color: 'warning.main' } : {},
      ),
  },
  {
    field: 'client_id',
    headerName: 'client',
    minWidth: 150,
    maxWidth: 300,
    // valueGetter: (params) => params.row.first_name + ' ' + params.row.last_name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.row.first_name + ' ' + item.row.last_name,
        client_id: item.row.client_id,
      });
    },
  },
  { field: 'listed_pacific', headerName: 'listed_date', minWidth: 175 },
  {
    field: 'starting_list_price',
    headerName: 'initial_list_price',
    valueFormatter: format.dollars,
  },
  { field: 'creds_status', minWidth: 120 },
  {
    field: 'creds_status_changed_pacific',
    headerName: 'creds_status_changed',
    minWidth: 175,
  },
  { field: 'event_date_pacific', headerName: 'event_date', minWidth: 175 },
  { field: 'event_name', headerName: 'description', minWidth: 400 },
  {
    field: 'seats',
    valueGetter: ({ row }) =>
      row.section +
      ' | ' +
      row.row +
      ' | ' +
      row.seats +
      ' | ' +
      'Q:' +
      row.seats.length +
      ' | ' +
      '$' +
      row.list_price,
  },
  {
    field: 'barcodes',
    type: 'boolean',
    valueGetter: ({ row }) => !!row.skybox.barCodesEntered,
  },
  { field: 'is_model_priced', headerName: 'model_priced', type: 'boolean' },
];
const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Ticket Details',
    children: [
      { field: 'client_id' },
      { field: 'listed_pacific' },
      { field: 'starting_list_price' },
      { field: 'creds_status' },
      { field: 'creds_status_changed_pacific' },
      { field: 'event_date_pacific' },
      { field: 'event_name' },
      { field: 'seats' },
      { field: 'barcodes' },
      { field: 'is_model_priced' },
    ],
  },
  {
    groupId: 'Actions',
    children: [{ field: 'BROADCAST' }, { field: 'DELETE' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

export default function AwaitingBroadcastDataGrid({
  rows,
  reloadData,
}: {
  rows: any[];
  reloadData: () => Promise<void>;
}) {
  return PageDataGrid({
    id: 'awaiting_broadcast_tickets',
    rows,
    column_specs: [
      ...column_specs,
      getActionColumnDef({
        field: 'BROADCAST',
        headerName: 'broadcast',
        action: 'broadcast',
        cellOnClick: async (row: any) => {
          await broadcastInventory(row.id);
          reloadData();
        },
        button_color: 'primary',
      }),
      getActionColumnDef({
        field: 'DELETE',
        headerName: 'remove',
        action: 'delete',
        cellOnClick: async (row: any) => {
          await unbroadcastInventory(row.id);
          reloadData();
        },
        confirm_popup_message:
          'The inventory will be removed from the awaiting broadcast queue and will not be broadcasted.',
      }),
    ],
    column_groups,
    filter_specs: [],
  });
}
