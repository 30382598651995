import { GridColDef } from '@mui/x-data-grid-pro';
import EventStatusColumn from './EventStatusColumn';
import { PageDataGrid, QuickFilterSpec } from '../../../common/components';
import {
  ConversionStage,
  LeagueId,
  QualifyStatus,
} from '../../../common/types';

const column_groups = [
  {
    groupId: 'Playoff Round Details',
    children: [
      { field: 'id' },
      { field: 'league' },
      { field: 'season' },
      { field: 'playoff_round' },
      { field: 'playoff_round_id' },
      { field: 'home_team' },
      { field: 'home_team_id' },
      { field: 'window_start' },
      { field: 'window_end' },
      { field: 'status' },
      { field: 'status_is_loading' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Actions',
    children: [{ field: 'DELETE' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    minWidth: 80,
  },
  { field: 'league', minWidth: 100 },
  { field: 'season', minWidth: 120 },
  { field: 'home_team', minWidth: 150 },
  { field: 'home_team_id', minWidth: 120 },
  { field: 'playoff_round', minWidth: 150 },
  { field: 'playoff_round_id', minWidth: 120 },
  {
    field: 'window_start',
    minWidth: 175,
    valueFormatter: (params) =>
      params.value ? `${params.value} 00:00 am` : '',
  },
  {
    field: 'window_end',
    minWidth: 175,
    valueFormatter: (params) =>
      params.value ? `${params.value} 00:00 am` : '',
  },
];

const filter_specs: QuickFilterSpec<
  | '2022'
  | '2023'
  | LeagueId
  | 'pending'
  | 'listable'
  | 'not_happening'
  | 'completed'
>[] = [
  {
    id: 'season',
    label: 'Season',
    optionValues: [
      { label: '2022', value: '2022' },
      { label: '2023', value: '2023' },
    ],
  },
  {
    id: 'league',
    label: 'League',
    optionValues: [
      { label: LeagueId.NFL, value: LeagueId.NFL },
      { label: LeagueId.NBA, value: LeagueId.NBA },
      { label: LeagueId.NHL, value: LeagueId.NHL },
      { label: LeagueId.MLB, value: LeagueId.MLB },
      { label: LeagueId.MLS, value: LeagueId.MLS },
      { label: LeagueId.NCAAB, value: LeagueId.NCAAB },
      { label: LeagueId.NCAAF, value: LeagueId.NCAAF },
    ],
  },
  {
    id: 'status',
    label: 'Status',
    optionValues: [
      { label: 'Pending', value: 'pending' },
      { label: 'Listable', value: 'listable' },
      { label: 'Not happening', value: 'not_happening' },
      { label: 'Completed', value: 'completed' },
    ],
  },
];

const PlayoffGameDataGrid = ({
  rows,
  actionColumns,
}: {
  rows: any[];
  actionColumns: GridColDef[];
}) => {
  return PageDataGrid({
    id: 'playoff-rounds',
    rows,
    column_specs: [...column_specs, ...actionColumns, EventStatusColumn()],
    column_groups,
    filter_specs,
    initialState: { pinnedColumns: { right: ['DELETE'] } },
    hide_client_filter: true,
  });
};

export default PlayoffGameDataGrid;
