import h from '../../lib/react-hyperscript';
import { useEffect, useRef } from 'react';
import { innerHeight } from '../util/gfx';

import {
  range as d3_range,
  select as d3_select,
  scaleLinear as d3_scaleLinear,
} from 'd3';

type SparkBarData = {
  min: number;
  max: number;
  origin: number;
  points: {
    value: number;
    opacity: number;
  }[];
};

const drawSparkBarOn = ({
  data,
  width,
  height,
  points_shown = 10,
  el,
}: {
  data: SparkBarData;
  width: number;
  height: number;
  points_shown: number;
  el: HTMLElement;
}) => {
  // Attribution: https://web.archive.org/web/20240417142544/https://www.essycode.com/posts/create-sparkline-charts-d3/

  const WIDTH = width;
  const HEIGHT = height;
  const DATA_MAX = data.max - data.origin;
  const DATA_MIN = data.min - data.origin;
  const DATA_COUNT = points_shown;
  const BAR_WIDTH = (WIDTH - DATA_COUNT) / DATA_COUNT;
  const norm_points = data.points.slice(-points_shown).map((p) => ({
    value: p.value - data.origin,
    opacity: p.opacity,
  }));

  const x = d3_scaleLinear().domain([0, DATA_COUNT]).range([0, WIDTH]);
  const y = d3_scaleLinear().domain([DATA_MIN, DATA_MAX]).range([HEIGHT, 0]);

  const svg = d3_select(el)
    .append('svg')
    .attr('width', WIDTH)
    .attr('height', HEIGHT)
    .append('g');
  svg
    .selectAll('.bar')
    .data(norm_points)
    .enter()
    .append('rect')
    .attr('class', 'bar')
    .attr('x', (d, i) => x(i))
    .attr('y', (d) => (d.value > 0 ? y(d.value) : y(0)))
    .attr('width', BAR_WIDTH)
    .attr('height', (d) => Math.abs(y(d.value) - y(0)))
    .attr('fill', (d) => (d.value > 0 ? 'steelblue' : 'tomato'))
    .attr('opacity', (d) => d.opacity);
};

function Sparkbar({
  width = '100%',
  height = '100%',
  padding = '0 0 0 0',
  data,
  points_shown,
}: {
  width?: string;
  height?: string;
  padding?: string;
  data: SparkBarData;
  points_shown: number;
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && data.points.length) {
      const { width, height } = innerHeight(ref.current);
      (ref.current as HTMLElement).replaceChildren();
      drawSparkBarOn({
        el: ref.current,
        height,
        width,
        data,
        points_shown,
      });
    }
  }, []);

  return h('div.sparkbar', {
    ref,
    style: {
      height,
      width,
      padding,
      boxSizing: 'border-box',
    },
  });
}

export { Sparkbar };
