import h from '../../../lib/react-hyperscript';
import { Stack, Box, Typography, Button, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAsyncFn } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import { InventoryItem, TaxStatus } from '../../../common/types';
import * as _ from 'ramda';
import { lightFormat } from 'date-fns';
import { fmt, durationDays } from '../../../common/helper';

import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { ClientCard } from '../../../common/components';
import * as api from '../../../common/api';
import * as Plot from '@observablehq/plot';

import { useParams } from 'react-router-dom';
import { useCachedData } from '../../../common/cached_data';
import { Subject as SubjectIcon } from '@mui/icons-material';
import NoteDetailsDialog from '../../../common/components/NoteDetailsDialog';

const fetchFunc = async (opts: {
  id: number;
  updateFn?: () => Promise<void>;
}) => {
  if (opts.updateFn) {
    await opts.updateFn();
  }

  const summary = await api.getClientSummary(opts.id);
  const activity_summary = await api.getClient(opts.id);
  const inventory = await api.getClientInventory(opts.id);
  const taxStatus = await api.getTaxStatus(opts.id);

  return { summary, activity_summary, inventory, taxStatus };
};

const taxStatusMapping = {
  submitted: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
};

const getTokenizerUrl = (token: string) => {
  return `${process.env.REACT_APP_TOKENIZER_URL}/${token}`;
};

const getTaxStatusValue = (status?: TaxStatus) => {
  return status ? taxStatusMapping[status] : null;
};

const getDateValue = (date: string | null | undefined) =>
  _.isNil(date) ? null : fmt.datetime(date || '');

const LabeledValue = ({
  label,
  value,
  href,
}: {
  label: string;
  value: string | null | undefined;
  href?: string | null | undefined;
}) =>
  h(Stack, { direction: 'row', spacing: 1 }, [
    h(Typography, { sx: { width: '250px', textAlign: 'left' } }, label + ':'),
    _.isNil(value)
      ? h(Typography, { color: 'gray' }, '-')
      : href
      ? h('a', { href, target: '_blank' }, value)
      : h(Typography, value),
  ]);

const dateFormat = (date: Date | undefined | null) =>
  date
    ? lightFormat(new Date(date), 'MM-dd-yyyy hh:mm a').toLowerCase()
    : 'never';

function subtractYears(date: Date, years: number) {
  const result = new Date(date);
  result.setFullYear(date.getFullYear() - years);
  return result;
}
const ClientInventoryChart = ({
  inventory,
}: {
  inventory: InventoryItem[];
}) => {
  const containerRef = useRef<HTMLElement>();

  const inv = _.pipe(
    _.filter(
      (item: InventoryItem) => item.is_sold && !!item.invoice_date, //        numDays(new Date(), new Date(item.invoice_date)) < 365,
    ),
    _.map((item) => {
      const list_date = item.listed ? new Date(item.listed) : null;
      const list_price = item.starting_list_price
        ? item.starting_list_price
        : null;
      const sale_date = new Date(item.invoice_date ?? '');
      const sale_price = item.client_facing_sale_price ?? 0;
      const league = item.league || 'other';
      const num_seats = item.seats.length;
      const event_date = new Date(item.event_date_local);

      const days_listed = list_date
        ? durationDays(list_date, new Date(sale_date))
        : null;

      const days_to_event = durationDays(sale_date, new Date(event_date));

      const drop_pct = list_price && (list_price - sale_price) / list_price;

      const drop_shock =
        drop_pct && days_listed
          ? (drop_pct * (180 - Math.min(days_listed, 89))) / 180
          : 0;

      const is_playoffs = !_.isNil(item.playoff_round_id);

      return {
        ...item,
        list_date,
        list_price,
        sale_date,
        sale_price,
        drop_pct,
        league,
        num_seats,
        event_date,
        days_listed,
        days_to_event,
        drop_shock,
        is_playoffs,
      };
    }),
  )(inventory);

  useEffect(() => {
    if (inv.length === 0) return;

    const now = new Date();
    const xEnd = new Date();
    xEnd.setDate(new Date().getDate() + 90);

    // We might not always have list_dates for sales, so a sale_date could be
    // the earliest data point.
    const list_dates = _.pluck('list_date', inv).filter((a) => a) as Date[];
    const oldest = list_dates
      .concat(_.pluck('sale_date', inv))
      .reduce((a, b) => (a < b ? a : b));
    const yearAgo = subtractYears(now, 1);
    const xStart = oldest < yearAgo ? oldest : yearAgo;

    const channels = {
      list_date: 'list_date',
      list_price: 'list_price',
      drop_pct: 'drop_pct',
      drop_shock: 'drop_shock',
      days_listed: 'days_listed',
      days_to_event: 'days_to_event',
      team: 'home_team',
      is_playoffs: 'is_playoffs',
      event: 'event_name',
      event_date: 'event_date',
      section: 'section',
      row: 'row',
      seats: 'seats',
    };

    //Convert every inventory item to a Plot line segment
    const history_lines = inv.map((item) =>
      Plot.line(
        [
          {
            x: item.list_date,
            y: item.list_price && item.list_price,
            league: item.league,
          },
          { x: item.sale_date, y: item.sale_price, league: item.league },
        ],
        {
          channels,
          x: 'x',
          y: 'y',
          stroke: 'league',
          opacity: Math.max(item.drop_shock, 0.1),
        },
      ),
    );

    const event_lines = inv.map((item) => {
      return Plot.line(
        [
          {
            x: item.sale_date,
            y: item.sale_price,
            league: item.league,
          },
          { x: item.event_date, y: item.sale_price, league: item.league },
        ],
        {
          channels,
          x: 'x',
          y: 'y',
          stroke: 'league',
          strokeWidth: item.num_seats * 2,
          opacity: Math.max((90 - Math.min(item.days_to_event, 89)) / 120, 0.1),
        },
      );
    });

    const plot = Plot.plot({
      width: 1500,
      height: 400,
      y: {
        grid: true,
        label: 'Sale Price ($)',
      },
      x: {
        domain: [xStart, xEnd],
        label: 'Sale Date',
      },
      color: {
        legend: true,
        domain: ['NBA', 'NFL', 'MLB', 'NHL', 'MLS', 'other'],
      },
      marks: [
        Plot.ruleY([0]),
        ...history_lines,
        ...event_lines,
        Plot.dot(inv, {
          channels,
          x: 'sale_date',
          y: 'sale_price',
          fill: 'league',
          tip: true,
          r: 'num_seats',
        }),
      ],
    });
    containerRef?.current?.append(plot);
    return () => plot.remove();
  }, [inv, inventory]);

  return h('div', { ref: containerRef });
};

export default function ClientPanel() {
  const { invalidateData } = useCachedData('clients');
  const { client_id } = useParams<{ client_id: string }>();
  const id = parseInt(client_id as string, 10);

  const [state, doFetch] = useAsyncFn(fetchFunc);
  const [updating, setUpdating] = useState(false);
  const [is_note_dialog_open, setNoteDialogOpen] = useState(false);

  const setNoteHeader = async (note_header: string) => {
    await doFetch({
      updateFn: () =>
        api.updateNoteHeader({
          row: { id },
          new_value: note_header,
          api_key_field: 'id',
          ref_source: 'client_info',
          ref_usage: 'general',
        }),
      id,
    });
  };

  const handleInvalidateBank = async () => {
    try {
      setUpdating(true);
      await api.invalidateBankAccount(id);
      invalidateData();
      await doFetch({ id });
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    doFetch({ id });
  }, [id, doFetch]);

  const data = state.value;

  return h(
    Stack,
    {
      alignItems: 'center',
    },
    [
      h(FetchBackdrop, { state }),
      !data
        ? []
        : h(
            Box,
            {
              sx: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            },

            [
              h(
                Typography,
                {
                  sx: { zIndex: 10 },
                  variant: 'h5',
                  fontWeight: 'bold',
                  mb: 4,
                },
                data.summary.first_name + ' ' + data.summary.last_name,
              ),
              h(
                Grid,
                {
                  container: true,
                  spacing: 3,
                  columns: { xs: 1, sm: 1, md: 2 },
                  width: '100%',
                  sx: { mb: 2 },
                },
                [
                  h(Grid, { key: 0, xs: 1 }, [
                    h(Typography, { variant: 'h4', mb: 1 }, 'Info'),

                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Notes',
                    ),
                    h(Paper, { elevation: 3, sx: { mb: 2 } }, [
                      h(Box, { pr: 2, pb: 2, pl: 2, pt: 2 }, [
                        h(
                          Button,
                          {
                            sx: {
                              width: '100%',
                              minHeight: '30px',
                              justifyContent: 'start',
                              alignItems: 'start',
                              background: '#eff0f3',
                              padding: '8px 12px',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#d0d4db',
                                boxShadow: 'none',
                              },
                            },
                            size: 'small',
                            onClick: () => setNoteDialogOpen(true),
                          },
                          [
                            state.value?.summary.has_notes
                              ? h(SubjectIcon, {
                                  color: 'info',
                                  fontSize: 'small',
                                })
                              : null,
                            h(
                              Typography,
                              {
                                fontWeight: 550,
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textAlign: 'left',
                              },
                              state.value?.summary.note || '',
                            ),
                          ],
                        ),
                      ]),
                    ]),

                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Details',
                    ),
                    h(ClientCard, {
                      client_summary: data.summary,
                      is_editable: true,
                      onSave: (payload: { phone: string; email: string }) => {
                        doFetch({
                          id,
                          updateFn: () => api.updateClientInfo(id, payload),
                        }).then(() => {
                          invalidateData();
                        });
                      },
                    }),
                  ]),

                  h(Grid, { key: 1, xs: 1 }, [
                    h(Typography, { variant: 'h4', mb: 1 }, 'Stats'),
                    h(LabeledValue, {
                      label: 'Registered',
                      value: dateFormat(
                        data.activity_summary.client_registered,
                      ),
                    }),
                    h(LabeledValue, {
                      label: 'Commission experiment',
                      value:
                        data.activity_summary.commission_experiment ===
                        'experiment'
                          ? 'Yes'
                          : 'No',
                    }),
                    h(LabeledValue, {
                      label: 'Inventory awaiting broadcast',
                      value: data.activity_summary.num_awaiting_broadcast,
                    }),
                    h(LabeledValue, {
                      label: 'Inventory broadcasted',
                      value: data.activity_summary.num_broadcasted,
                    }),
                    h(LabeledValue, {
                      label: 'Inventory sold',
                      value: data.activity_summary.num_sold,
                    }),
                    h(LabeledValue, {
                      label: 'Median game value',
                      value:
                        fmt.dollars(data.activity_summary.median_game_value) ||
                        null,
                    }),
                    h(LabeledValue, {
                      label: 'Total sold value',
                      value:
                        fmt.dollars(data.activity_summary.sold_amount) || null,
                    }),
                    h(
                      Typography,
                      { variant: 'h4', mt: 2, mb: 1 },
                      'Tax Information',
                    ),
                    h(LabeledValue, {
                      label: 'Tax status',
                      value: getTaxStatusValue(data.taxStatus?.status),
                    }),
                    h(LabeledValue, {
                      label: 'Non-resident alien',
                      value: _.isNil(data.taxStatus?.is_nonresident_alien)
                        ? null
                        : data.taxStatus?.is_nonresident_alien
                        ? 'Yes'
                        : 'No',
                    }),
                    h(LabeledValue, {
                      label: 'Updated at',
                      value: getDateValue(data.taxStatus?.updated),
                    }),
                    h(LabeledValue, {
                      label: 'First target payment date',
                      value: getDateValue(
                        data.activity_summary.first_target_payment_date,
                      ),
                    }),
                    h(
                      Typography,
                      { variant: 'h4', mt: 2, mb: 1 },
                      'Bank Account',
                    ),
                    h(LabeledValue, {
                      label: 'Bank added',
                      value: getDateValue(data.activity_summary?.bank_created),
                    }),
                    h(LabeledValue, {
                      label: 'Bank updated',
                      value: getDateValue(data.activity_summary?.bank_updated),
                    }),
                    h(LabeledValue, {
                      label: 'Holder name',
                      value: data.activity_summary?.bank_holder_name,
                    }),
                    h(LabeledValue, {
                      label: 'Is ACH (US bank account)',
                      value: _.isNil(data.activity_summary?.bank_account_is_ach)
                        ? null
                        : data.activity_summary.bank_account_is_ach
                        ? 'Yes'
                        : 'No',
                    }),
                    h(LabeledValue, {
                      label: 'Routing number',
                      value: data.activity_summary?.bank_routing_token
                        ? 'Show value'
                        : null,
                      href: getTokenizerUrl(
                        data.activity_summary?.bank_routing_token ?? '',
                      ),
                    }),
                    h(LabeledValue, {
                      label: 'Bank account',
                      value: data.activity_summary?.bank_account_token
                        ? 'Show value'
                        : null,
                      href: getTokenizerUrl(
                        data.activity_summary?.bank_account_token ?? '',
                      ),
                    }),
                    !data.activity_summary.bank_account_invalidated
                      ? data.activity_summary.bank_account_is_ach === false
                        ? h(
                            Button,
                            {
                              variant: 'outlined',
                              sx: { marginTop: 2 },
                              onClick: handleInvalidateBank,
                              disabled: updating,
                            },
                            'Invalidate Bank Account',
                          )
                        : null
                      : h(LabeledValue, {
                          label: 'Invalidated at',
                          value: getDateValue(
                            data.activity_summary.bank_account_invalidated,
                          ),
                        }),
                  ]),
                ],
              ),

              h(Box, [
                h(Typography, { variant: 'h4', mb: 2 }, 'Sales'),
                h(ClientInventoryChart, {
                  inventory: data.inventory,
                }),
              ]),
            ],
          ),

      is_note_dialog_open
        ? h(NoteDetailsDialog, {
            cur_header: state.value?.summary?.note ?? '',
            entry_desc: `${
              state.value?.summary.first_name +
              ' ' +
              state.value?.summary.last_name
            } (phone: ${state.value?.summary.phone}, email: ${
              state.value?.summary.email
            })`,
            saveNoteHeaderFn: (new_value) => setNoteHeader(new_value),
            saveNoteBodyFn: (new_value) =>
              api.updateNoteDetail({
                row: { id },
                new_value: new_value,
                api_key_field: 'id',
                ref_source: 'client_info',
                ref_usage: 'general',
              }),
            getDataFn: () =>
              api.getNoteDetail({
                row: { id },
                api_key_field: 'id',
                ref_source: 'client_info',
                ref_usage: 'general',
              }),
            onClose: () => setNoteDialogOpen(false),
          })
        : null,
    ],
  );
}
