import { useBaseData } from '../../../common/base_data';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import { prettyUsNumber } from '../../../common/util/phone';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import {
  ConversionStage,
  LeagueId,
  QualifyStatus,
} from '../../../common/types';
import { stringDateTime } from '../../../common/util';
import DataGridNoteColumnV2 from '../../../common/components/DataGridNoteColumnV2';
import * as api from '../../../common/api';
import { useEffect, useState } from 'react';

const column_specs: GridColDef[] = [
  {
    field: 'client_id',
    headerName: 'id',
    align: 'center',
    width: 80,
    resizable: false,
    hideable: false,
    pinnable: false,
    valueGetter: ({ row }) => row.id,
    renderCell: (params) =>
      DataGridEntryButton(params, `/clients/${params.id}`, {
        color: 'secondary.main',
      }),
  },
  {
    field: 'client_name',
    headerName: 'name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: ({ row }) => row.name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.id,
      });
    },
  },
  { field: 'email', minWidth: 180 },
  {
    field: 'phone',
    minWidth: 120,
    renderCell: (item) => {
      return h('div', prettyUsNumber(item.value ?? ''));
    },
  },
  { field: 'first_season', width: 75 },
  { field: 'last_season', width: 75 },
  { field: 'leagues', minWidth: 120 },
  { field: 'teams', width: 120 },
  { field: 'campaign', width: 120 },
  {
    field: 'commission_experiment',
    width: 140,
    type: 'boolean',
    headerName: 'experiment',
    valueGetter: ({ value }) => value === 'experiment',
  },

  { field: 'tax_status', minWidth: 120, headerName: 'status' },
  { field: 'tax_attempts', minWidth: 120, headerName: 'attempts' },
  { field: 'is_nonresident_alien', type: 'boolean', minWidth: 220 },
  stringDateTime({
    field: 'first_target_payment_date',
    minWidth: 220,
  }),
  stringDateTime({
    field: 'tax_updated',
    minWidth: 220,
  }),

  { field: 'bank_holder_name', width: 140 },
  {
    field: 'bank_account_payment_processor',
    headerName: 'payment_processor',
    minWidth: 180,
  },
  { field: 'bank_account_status', minWidth: 180 },

  stringDateTime({
    field: 'bank_created',
    minWidth: 175,
  }),
  stringDateTime({
    field: 'bank_updated',
    minWidth: 175,
  }),

  {
    headerName: 'stage',
    field: 'conversion_stage',
    minWidth: 120,
  },
  stringDateTime({
    field: 'client_registered',
    minWidth: 175,
    headerName: 'registered',
  }),
  stringDateTime({
    field: 'client_last_seen',
    minWidth: 175,
    headerName: 'last_seen',
  }),
  {
    field: 'last_listed',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'first_listed',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'last_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'first_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'customer_third_sale',
    width: 150,
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    headerName: 'third_sold',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },

  {
    field: 'num_events',
    align: 'right',
    headerName: 'total',
    type: 'number',
  },
  {
    field: 'num_days_used',
    align: 'right',
    headerName: 'days_used',
    type: 'number',
  },

  {
    field: 'ticket_views',
    align: 'right',
    headerName: 'my_tix_views',
    type: 'number',
  },
  {
    field: 'unlisted_item_taps',
    align: 'right',
    type: 'number',
  },
  {
    field: 'show_listing_policy',
    align: 'right',
    headerName: 'saw_listing_policy',
    type: 'number',
  },
  {
    field: 'accept_listing_policy',
    align: 'right',
    headerName: 'accepted_listing_policy',
    type: 'number',
  },
  {
    field: 'canceled_credentials_interrupter',
    align: 'right',
    type: 'number',
  },

  {
    field: 'median_game_value',
    valueFormatter: format.dollars,
    align: 'right',
    type: 'number',
  },
  {
    field: 'pct_seats_sold_listed',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'total_value',
    valueFormatter: format.dollars,
    align: 'right',
    type: 'number',
    valueGetter: ({ row }) => row.sold_or_listed_amount + row.unlisted_amount,
  },
  {
    field: 'sold_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sold_or_listed_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'unlisted_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  { field: 'avg_days_listed', align: 'right', type: 'number' },
  {
    field: 'avg_starting_list_price',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'avg_current_list_price',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  { field: 'avg_listing_days_to_event', align: 'right', type: 'number' },
];

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'client_id' }],
  },
  {
    groupId: 'Details',
    children: [
      { field: 'client_name' },
      { field: 'email' },
      { field: 'phone' },
      { field: 'first_season' },
      { field: 'last_season' },
      { field: 'leagues' },
      { field: 'teams' },
      { field: 'campaign' },
      { field: 'commission_experiment' },
    ],
  },

  {
    groupId: 'Tax info',
    children: [
      { field: 'tax_status' },
      { field: 'tax_attempts' },
      { field: 'is_nonresident_alien' },
      { field: 'tax_updated' },
      { field: 'first_target_payment_date' },
    ],
  },

  {
    groupId: 'Bank info',
    children: [
      { field: 'bank_holder_name' },
      { field: 'bank_account_payment_processor' },
      { field: 'bank_account_status' },
      { field: 'bank_created' },
      { field: 'bank_updated' },
    ],
  },

  {
    groupId: 'Activity',
    children: [
      { field: 'conversion_stage' },
      { field: 'client_registered' },
      { field: 'client_last_seen' },
      { field: 'last_listed' },
      { field: 'first_listed' },
      { field: 'last_sold' },
      { field: 'first_sold' },
      { field: 'customer_third_sale' },
    ],
  },

  {
    groupId: 'Events',
    children: [
      { field: 'num_days_used' },
      { field: 'num_events' },
      { field: 'ticket_views' },
      { field: 'unlisted_item_taps' },
      { field: 'show_listing_policy' },
      { field: 'accept_listing_policy' },
      { field: 'canceled_credentials_interrupter' },
    ],
  },

  {
    groupId: 'Stats',
    children: [
      { field: 'total_value' },
      { field: 'median_game_value' },
      { field: 'pct_seats_sold_listed' },
      { field: 'sold_amount' },
      { field: 'sold_or_listed_amount' },
      { field: 'unlisted_amount' },
      { field: 'avg_days_listed' },
      { field: 'avg_starting_list_price' },
      { field: 'avg_current_list_price' },
      { field: 'avg_listing_days_to_event' },
    ],
  },

  {
    groupId: 'Note',
    children: [{ field: 'note' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const filter_specs: QuickFilterSpec<ConversionStage>[] = [
  {
    id: 'conversion_stage',
    label: 'Stage',
    optionValues: [
      { label: 'Sold', value: ConversionStage.SOLD },
      { label: 'Delisted all', value: ConversionStage.DELISTED_ALL },
      { label: 'Listed', value: ConversionStage.LISTED },
      { label: 'Logged on', value: ConversionStage.LOGGED_ON },
      { label: 'Activated', value: ConversionStage.ACTIVATED },
      { label: 'Supported', value: ConversionStage.SUPPORTED },
      { label: 'Unsupported', value: ConversionStage.NOT_SUPPORTED },
      { label: 'Pending', value: ConversionStage.PENDING },
    ],
  },
];

export default function ClientsDataGrid({
  rows,
  onDataUpdated,
}: {
  rows: any[];
  onDataUpdated: () => void;
}) {
  const {
    baseData: { include_test_data },
  } = useBaseData();
  const [data, setData] = useState(rows);
  const onSaveSucceed = (row: any, field: string, value: any) => {
    const new_data = [...data];
    const target_idx = data.findIndex((item) => item.id === row.id);
    new_data[target_idx][field] = value;
    setData(new_data);
    onDataUpdated();
  };
  useEffect(() => {
    setData(rows);
  }, [rows]);

  return PageDataGrid({
    id: 'clients',
    data_grid_id: 'client_id',
    rows: _.filter((r) => r.is_client || include_test_data, data),
    column_specs: [
      ...column_specs,
      DataGridNoteColumnV2({
        field: 'note',
        valueGetter: ({ row }) => row.note,
        headerName: 'client',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.name} (phone: ${row.phone}, email: ${row.email})`,
        hasNoteBody: (row) => !!row.has_notes,
        api_key_field: 'id',
      }),
    ],
    column_groups,
    filter_specs,
  });
}
