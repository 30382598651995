import h from '../../../lib/react-hyperscript';

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Stack,
  Chip,
  Typography,
} from '@mui/material';
import {
  Stadium as StadiumIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  Close as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  SmartToyOutlined as SmartToyIcon,
} from '@mui/icons-material';
import { ClientEvent, InventoryItem } from '../../../common/types';
import * as _ from 'ramda';
import MarketplaceEventLinkGroup from '../../../common/components/MarketplaceEventLinkGroup';
import { format } from '../../../common/components';

const getSeatShortcut = (inv: InventoryItem) => {
  const seats = inv.seats.sort((a, b) => {
    const numA = parseInt(a, 10);
    const numB = parseInt(b, 10);
    if (numA === numB) return a.length - b.length;
    return numA - numB;
  });
  if (seats.length === 0) return '';
  if (seats.length === 1) return seats[0];
  return `${seats[0]}-${seats[seats.length - 1]}`;
};

const getPricingStatus = (inv: InventoryItem, is_playoff: boolean) => {
  if (!inv.is_free_listing && !parseFloat(inv.tiq_commission ?? '0'))
    return h(Box, { color: 'red' }, 'No commission');
  if (!inv.is_priced) return h(Box, { color: 'red' }, 'Unpriced');
  if (is_playoff && !inv.is_price_shown)
    return h(Box, { color: 'red' }, 'Not Shown');
  return h(Box, { color: 'green' }, 'Ready');
};

const getClientAction = (inv: InventoryItem) => {
  if (!inv.listed && !inv.delisted) return '';
  if (inv.listed && !inv.delisted)
    return h(Box, {}, [
      'listed: ' +
        format.datetime({
          value: `${inv.listed}`,
        }),
    ]);
  if (inv.delisted && !inv.listed)
    return h(Box, {}, [
      'delisted: ' +
        format.datetime({
          value: `${inv.delisted}`,
        }),
    ]);
  if ((inv.listed as Date) > (inv.delisted as Date))
    return h(Box, {}, [
      'listed: ' +
        format.datetime({
          value: `${inv.listed}`,
        }),
    ]);
  else
    return h(Box, {}, [
      'delisted: ' +
        format.datetime({
          value: `${inv.delisted}`,
        }),
    ]);
};

export const getEventStatus = ({
  inventory,
  size,
}: {
  inventory?: InventoryItem;
  size?: 'medium' | 'small';
}) => {
  if (!inventory) return '';
  if (inventory.is_sold)
    return h(Chip, {
      color: 'warning',
      label: 'Sold',
      sx: { color: '#fff' },
    });
  if (inventory.is_broadcasted)
    return h(Chip, { color: 'primary', label: 'Broadcasted' });
  if (inventory.is_awaiting_broadcast)
    return h(Chip, {
      color: 'error',
      label: 'Awaiting broadcast',
      sx: { color: '#fff' },
    });
  return h(Chip, { label: 'Unlisted', size });
};

export default function EventsTable({
  events,
  selected,
  onChangeSelected,
  isSplitInventory,
  isRowMismatched,
  isSectionMismatched,
  is_delete_toggled,
  cur_tps_key,
  is_tp_shown,
  is_playoff,
}: {
  events: ClientEvent[];
  selected: Record<number, boolean>;
  onChangeSelected: (selected: Record<number, boolean>) => void;
  isSplitInventory: (inv: InventoryItem) => boolean;
  isRowMismatched: (inv?: InventoryItem) => boolean;
  isSectionMismatched: (inv?: InventoryItem) => boolean;
  is_delete_toggled: boolean;
  cur_tps_key: string;
  is_tp_shown: boolean;
  is_playoff: boolean;
}) {
  const handleCheck = (id: number, is_checked: boolean) => {
    const newSelected = _.set(_.lensProp(id), is_checked, selected);
    onChangeSelected(newSelected);
  };

  const handleCheckAll = (is_checked: boolean) => {
    const new_selected = _.mapObjIndexed(() => is_checked, selected);
    onChangeSelected(new_selected);
  };

  const isInvFromOtherTicketPackage = (inv?: InventoryItem): boolean => {
    return !!inv && inv.tps_key !== cur_tps_key;
  };

  const isInvDeletable = (inv?: InventoryItem): boolean => {
    return (
      !!inv && !inv.is_broadcasted && !inv.is_sold && !inv.is_awaiting_broadcast
    );
  };

  const isAnyUnmappedEvent = () => {
    const unmapped_inv = events.filter(
      (event) => event.inv && !event.inv.tm_event_id,
    );
    const mapped_inv = events.filter(
      (event) => event.inv && event.inv.tm_event_id,
    );

    return unmapped_inv.length > 0 && mapped_inv.length > 0;
  };

  return h(Box, [
    h(TableContainer, [
      h(Table, { sx: { size: 'small', ariaLabel: 'Events' } }, [
        h(TableHead, [
          h(TableRow, [
            h(
              TableCell,
              {
                sx: { pr: '0px', pb: '5px', pt: '5px', fontWeight: 'bold' },
              },
              [
                h(Checkbox, {
                  sx: { p: 0 },
                  checked: _.all(_.identity, _.values(selected)),
                  color: is_delete_toggled ? 'error' : 'primary',
                  disabled:
                    _.all(_.identity, _.values(selected)) &&
                    _.keys(selected).length === 0,
                  onChange: (e) => {
                    handleCheckAll(e.target.checked);
                  },
                }),
              ],
            ),
            h(
              TableCell,
              { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
              'Date',
            ),
            h(
              TableCell,
              { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
              'Name',
            ),
            h(
              TableCell,
              {
                sx: {
                  pb: '5px',
                  pt: '5px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  backgroundColor: isAnyUnmappedEvent() ? '#ffc' : 'inherit',
                },
              },
              'TM Event',
            ),
            h(
              TableCell,
              { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
              'Pricing',
            ),
            h(
              TableCell,
              { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
              'Status',
            ),
            h(
              TableCell,
              { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
              'List / Delist',
            ),
            h(
              TableCell,
              {
                sx: { pb: '5px', pt: '5px', fontWeight: 'bold' },
              },
              'Marketplaces',
            ),
          ]),
        ]),
        h(
          TableBody,
          events.map(
            ({
              id,
              date,
              name,
              venue,
              inv,
              is_usual_venue,
              stubhubEventUrl,
              vividSeatsEventUrl,
              performer,
            }) => {
              const dateStr = new Date(date + 'Z').toLocaleDateString('en-US', {
                weekday: 'short',
                month: '2-digit',
                year: 'numeric',
                day: '2-digit',
                timeZone: 'UTC',
              });

              return h(
                TableRow,
                {
                  sx: {
                    opacity: isInvFromOtherTicketPackage(inv) ? 0.35 : 1,
                    border: isInvFromOtherTicketPackage(inv)
                      ? '2px dotted'
                      : '',
                    borderColor: isInvFromOtherTicketPackage(inv)
                      ? 'rgba(0,0,0,0.35)'
                      : '',
                    cursor: isInvFromOtherTicketPackage(inv)
                      ? 'pointer'
                      : 'inherit',
                  },
                  key: `${id}-${inv ? inv.id : ''}`,
                  onClick: () => {
                    if (isInvFromOtherTicketPackage(inv))
                      window.open(`/ticket-packages/${inv?.tp_id}`, '_blank');
                  },
                },
                [
                  h(
                    TableCell,
                    {
                      sx: {
                        pr: '0px',
                        pb: '5px',
                        pt: '5px',
                        fontWeight: 'bold',
                      },
                    },
                    [
                      !isInvFromOtherTicketPackage(inv)
                        ? (!is_delete_toggled && !inv) || isInvDeletable(inv)
                          ? h(Checkbox, {
                              sx: { p: 0 },
                              color: is_delete_toggled ? 'error' : 'primary',
                              checked:
                                ((is_delete_toggled
                                  ? !inv ||
                                    inv.is_sold ||
                                    inv.is_broadcasted ||
                                    inv.is_awaiting_broadcast
                                  : !!inv) ||
                                  selected[
                                    is_delete_toggled && inv ? inv.id : id
                                  ]) ??
                                false,
                              disabled: is_delete_toggled
                                ? !inv ||
                                  inv.is_sold ||
                                  inv.is_broadcasted ||
                                  inv.is_awaiting_broadcast
                                : !!inv,
                              onChange: (e) => {
                                handleCheck(
                                  is_delete_toggled && inv ? inv.id : id,
                                  e.target.checked,
                                );
                              },
                            })
                          : null
                        : null,
                    ],
                  ),
                  h(
                    TableCell,
                    {
                      component: 'th',
                      scope: 'row',
                      sx: { pb: '5px', pt: '5px', width: '130px' },
                    },
                    dateStr,
                  ),

                  h(TableCell, { sx: { pb: '5px', pt: '5px' } }, [
                    h(Box, name),
                    is_usual_venue
                      ? null
                      : h(
                          Stack,
                          {
                            direction: 'row',
                            sx: {
                              aliignItems: 'center',
                              backgroundColor: '#ffc',
                              width: 'fit-content',
                            },
                          },
                          [
                            h(StadiumIcon, {
                              sx: {
                                color: 'gray',
                                width: '18px',
                                height: '18px',
                                mr: 1,
                              },
                            }),
                            h(
                              Box,
                              `${venue?.name}, ${venue?.city}, ${venue?.state}`,
                            ),
                          ],
                        ),
                    inv
                      ? h(
                          Stack,
                          { direction: 'row', spacing: 2, flexWrap: 'wrap' },
                          [
                            h(Box, 'Com: $' + inv.tiq_commission),
                            h(Box, '3p: $' + inv.third_party_cost),
                            h(
                              Box,
                              {
                                sx: {
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: inv.is_model_priced
                                    ? '#ffffea'
                                    : 'inherit',
                                  fontWeight: inv.is_model_priced
                                    ? 'bold'
                                    : 'normal',
                                  textAlign: 'right',
                                  paddingRight: '8px',
                                  paddingLeft: '8px',
                                },
                              },
                              [
                                inv.is_model_priced
                                  ? h(SmartToyIcon, {
                                      sx: {
                                        width: '20px',
                                        height: '20px',
                                        mr: 0.5,
                                      },
                                    })
                                  : null,
                                'List: $' + inv.list_price,
                              ],
                            ),
                            inv.is_free_listing
                              ? h(
                                  Stack,
                                  { direction: 'row', alignItems: 'center' },
                                  [
                                    h(LocalOfferOutlinedIcon, {
                                      fontSize: 'small',
                                    }),
                                    h(
                                      Typography,
                                      {
                                        variant: 'caption',
                                        sx: { marginLeft: '4px' },
                                      },
                                      'Free Listing',
                                    ),
                                  ],
                                )
                              : null,
                          ],
                        )
                      : null,

                    !!inv && !isInvFromOtherTicketPackage(inv)
                      ? h(
                          Box,
                          {
                            sx: {
                              display: 'flex',
                              gap: 2,
                            },
                          },
                          [
                            isSectionMismatched(inv)
                              ? h(
                                  Box,
                                  { sx: { backgroundColor: 'yellow' } },
                                  `Section: ${inv.section}`,
                                )
                              : null,
                            isRowMismatched(inv)
                              ? h(
                                  Box,
                                  { sx: { backgroundColor: 'yellow' } },
                                  `Row: ${inv.row}`,
                                )
                              : null,
                            isSplitInventory(inv)
                              ? h(
                                  Box,
                                  { sx: { backgroundColor: 'yellow' } },
                                  `Seats: ${getSeatShortcut(inv)}`,
                                )
                              : null,
                          ],
                        )
                      : null,
                  ]),

                  h(
                    TableCell,
                    {
                      component: 'th',
                      scope: 'row',
                      sx: {
                        pb: '5px',
                        pt: '5px',
                        textAlign: 'center',
                        backgroundColor: isAnyUnmappedEvent()
                          ? '#ffc'
                          : 'inherit',
                      },
                    },
                    [
                      inv
                        ? inv.tm_event_id
                          ? h(CheckCircleIcon, { color: 'success' })
                          : h(ErrorIcon, {
                              color: 'error',
                              sx: {
                                fontWeight: 600,
                                fontSize: 40,
                              },
                            })
                        : null,
                    ],
                  ),

                  h(
                    TableCell,
                    {
                      component: 'th',
                      scope: 'row',
                      sx: { pb: '5px', pt: '5px' },
                    },
                    [inv ? getPricingStatus(inv, is_playoff) : null],
                  ),

                  !isInvFromOtherTicketPackage(inv)
                    ? h(TableCell, {}, [getEventStatus({ inventory: inv })])
                    : h(TableCell, {}, []),

                  !isInvFromOtherTicketPackage(inv)
                    ? h(TableCell, {}, [inv ? getClientAction(inv) : ''])
                    : h(TableCell, {}, []),

                  !isInvFromOtherTicketPackage(inv)
                    ? h(TableCell, {}, [
                        inv
                          ? h(MarketplaceEventLinkGroup, {
                              seat_quantity: inv.seats.length,
                              event: {
                                id,
                                date,
                                name,
                                venue,
                                is_usual_venue,
                                stubhubEventUrl,
                                vividSeatsEventUrl,
                                performer,
                              },
                            })
                          : '',
                      ])
                    : h(TableCell, {}, []),
                ],
              );
            },
          ),
        ),
      ]),
    ]),
    !events.length
      ? h(
          Stack,
          {
            sx: {
              pb: '5px',
              pt: '5px',
              display: 'flex',
              justify_content: 'center',
            },
          },
          'There are no events available. If no teams in this league are showing events, consider checking the league settings',
        )
      : '',
  ]);
}
