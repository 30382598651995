import h from '../../lib/react-hyperscript';
import { Dialog, DialogTitle, DialogContent, Box, Avatar } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import tiqassist from '../../tiqassist.jpeg';
import { useEffect, useState } from 'react';

const LoginDialog = ({
  open,
  onClose = () => {},
  onLoggedIn,
}: {
  open: boolean;
  onClose?: () => void;
  onLoggedIn: () => void;
}) => {
  const { loginWithRedirect, isLoading } = useAuth0();
  const [is_init_loading, setInitLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 2000);
  }, []);

  return h(
    Dialog,
    {
      open,
      onClose,
      fullWidth: true,
      maxWidth: 'xs',
      sx: { alignItems: 'center' },
    },
    [
      h(
        DialogTitle,
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        [
          h(Avatar, {
            alt: 'logo',
            src: tiqassist,
            sx: { width: 80, height: 80 },
          }),
          'Welcome back',
        ],
      ),
      h(DialogContent, { sx: { alignItems: 'center' } }, [
        h(Box, {}, [
          h(
            LoadingButton,
            {
              fullWidth: true,
              variant: 'contained',
              sx: { mt: 1 },
              loading: isLoading || is_init_loading,
              onClick: async () => {
                await loginWithRedirect();
              },
            },
            'Login',
          ),
        ]),
      ]),
    ],
  );
};

export default LoginDialog;
