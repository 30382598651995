import { GridColDef } from '@mui/x-data-grid-pro';
import { format } from '../components';

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const assertionError = (msg?: string) => {
  const err = new Error(msg);
  err.name = 'AssertionError';
  return err;
};

export const assertIsDefined: <T>(
  val: T,
  msg?: string,
) => asserts val is NonNullable<T> = (val, msg) => {
  if (val === undefined || val === null) {
    throw assertionError(msg ?? `Expected value to be defined`);
  }
};

export const stringDateTime = (options: GridColDef) => ({
  type: 'dateTime',
  valueFormatter: format.datetime,
  valueGetter: ({ value }: { value: string }) =>
    value ? new Date(value) : null,
  ...options,
});
