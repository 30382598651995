import h from '../../lib/react-hyperscript';
import PricingPanel from './components/PricingPanel';
import PackagePricingPanel from './components/PackagePricingPanel';
import InventoryPricingPanel from './components/InventoryPricingPanel';
import InventoryHistoryPanel from './components/InventoryHistoryPanel';

const routing = {
  element: h(PricingPanel),
  children: [
    {
      // For now, set this as the default location, in addition to the old /inventory
      // route
      path: '',
      element: h(PackagePricingPanel),
    },
    {
      path: 'inventory',
      element: h(InventoryPricingPanel),
    },
    {
      path: 'packages',
      element: h(PackagePricingPanel),
    },
    {
      path: 'inventory/:id',
      element: h(InventoryHistoryPanel),
    },
  ],
};

export { routing };
