import h from '../../lib/react-hyperscript';
import * as _ from 'ramda';
import { innerHeight } from '../util/gfx';

import {
  line as d3_line,
  select as d3_select,
  scaleLinear as d3_scaleLinear,
} from 'd3';
import { useEffect, useRef } from 'react';

type SparkLineData = {
  min: number;
  max: number;
  points: {
    value: number;
    opacity: number;
  }[];
};

const drawSparkLineOn = ({
  el,
  width,
  height,
  data,
  points_shown,
  show_end_dot,
}: {
  width: number;
  height: number;
  data: SparkLineData;
  points_shown: number;
  show_end_dot: boolean;
  el: HTMLElement;
}) => {
  const sold_dot_radius = 3;
  const stroke_thickness = 2;
  const points = data.points.slice(-points_shown);
  const start_point = points[0];

  const x = d3_scaleLinear()
    .domain([0, points_shown - 1])
    .range([0 + sold_dot_radius, width - sold_dot_radius]);
  const y = d3_scaleLinear()
    .domain([data.min, data.max])
    .range([height - sold_dot_radius, 0 + sold_dot_radius]);

  const svg = d3_select(el)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g');

  const line_segments: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    opacity: number;
  }[] = [];

  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    line_segments.push({
      x1: i,
      y1: start.value,
      x2: i + 1,
      y2: end.value,
      opacity: end.opacity,
    });
  }

  svg
    .selectAll('.line')
    .data(line_segments)
    .enter()
    .append('line')
    .attr('class', 'line')
    .attr('x1', (d) => x(d.x1))
    .attr('y1', (d) => y(d.y1))
    .attr('x2', (d) => x(d.x2))
    .attr('y2', (d) => y(d.y2))
    //.attr('stroke', `url(#line-gradient-${uuid})`)
    .attr('stroke', (d) => {
      return d.y2 < start_point.value ? 'steelblue' : 'tomato';
    })
    .attr('stroke-width', stroke_thickness)
    .attr('opacity', (d) => d.opacity);

  if (show_end_dot && points.length > 1) {
    svg
      .append('circle')
      .attr('r', sold_dot_radius)
      .attr('cx', x(points.length - 1))
      .attr('cy', y(points[points.length - 1].value))
      .attr(
        'fill',
        points[points.length - 1].value < start_point.value
          ? 'steelblue'
          : 'tomato',
      );
  }
};

function Sparkline({
  width = '100%',
  height = '100%',
  padding = '0 0 0 0',
  data,
  points_shown,
  show_end_dot = true,
}: {
  width?: string;
  height?: string;
  padding?: string;
  data: SparkLineData;
  points_shown: number;
  show_end_dot: boolean;
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && data.points.length) {
      const { width, height } = innerHeight(ref.current);
      (ref.current as HTMLElement).replaceChildren();
      drawSparkLineOn({
        el: ref.current,
        height,
        width,
        data,
        points_shown,
        show_end_dot,
      });
    }
  }, []);

  return h('div.sparkline', {
    ref,
    style: {
      height,
      width,
      padding,
      boxSizing: 'border-box',
    },
  });
}

export { Sparkline };
