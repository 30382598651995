import h from '../../../../lib/react-hyperscript';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { Info as InfoIcon, Error as ErrorIcon } from '@mui/icons-material';
import { useBaseData } from '../../../../common/base_data';
import * as _ from 'ramda';
import {
  ClientDrillDown,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../../common/components';
import DataGridEntryButton from '../../../../common/components/DataGridEntryButton';
import {
  ConversionStage,
  LeagueId,
  PlayoffTicketPackageInv,
  QualifyStatus,
} from '../../../../common/types';
import { useEffect, useState } from 'react';
import DataGridNoteColumn from '../DataGridNoteColumn';
import LeagueIcon from '../../../../common/components/LeagueIcon';
import DataGridCustomColumn from '../DataGridCustomColumn';
import * as api from '../../../../common/api';
import { stringDateTime } from '../../../../common/util';
import DataGridNoteColumnV2 from '../../../../common/components/DataGridNoteColumnV2';

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Playoff Package Details',
    children: [
      { field: 'created' },
      { field: 'client_name' },
      { field: 'client_id' },
      { field: 'season' },
      { field: 'league' },
      { field: 'team_name' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'sth_status' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Dashboard Setup',
    children: [
      { field: 'qualification' },
      { field: 'is_priced' },
      { field: 'is_shown' },
      { field: 'is_archived' },
      { field: 'first_shown' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Activity',
    children: [
      { field: 'client_registered' },
      { field: 'client_last_seen' },
      { field: 'creds_status' },
      { field: 'creds_status_changed' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Round checks',
    children: [],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Note',
    children: [{ field: 'note' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    maxWidth: 70,
    resizable: false,
    hideable: false,
    pinnable: false,
    renderCell: (params) =>
      DataGridEntryButton(params, `/ticket-packages/${params.id}`),
  },
  stringDateTime({
    field: 'created',
    minWidth: 175,
  }),
  {
    field: 'client_name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },

  { field: 'season', width: 75 },
  { field: 'league', minWidth: 80 },
  {
    field: 'team_name',
    minWidth: 120,
  },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
    minWidth: 50,
    maxWidth: 100,
  },
  {
    field: 'sth_status',
    minWidth: 50,
    maxWidth: 100,
    valueGetter: ({ row }) =>
      row.sth_status
        ? row.sth_status +
          (row.sth_status === 'PSTH'
            ? (' - ' + row.num_games).padStart(2, '0')
            : '')
        : null,
  },

  stringDateTime({
    field: 'client_registered',
    minWidth: 175,
  }),

  stringDateTime({
    field: 'client_last_seen',
    minWidth: 175,
  }),

  { field: 'creds_status', minWidth: 120 },
  stringDateTime({
    field: 'creds_status_changed',
    minWidth: 175,
  }),
];

const getInventoryStatus = (
  is_shown: boolean,
  inv?: PlayoffTicketPackageInv,
  is_price_shown?: boolean,
) => {
  if (inv?.event_status === 'listable' && !inv?.is_priced) return 'Needs price';
  if (!is_shown) return 'TP Not Shown';
  if (inv?.event_status === 'pending' && inv?.is_priced) return 'Priced';
  if (inv?.event_status === 'pending' && !inv?.is_priced) return 'Unpriced';
  if (inv?.event_status === 'listable' && inv?.is_priced && !is_price_shown)
    return 'Price Not Shown';
  if (inv?.event_status === 'listable' && inv?.is_priced && !!is_price_shown)
    return 'Listable';
  if (inv?.event_status === 'not_happening') return 'Not happening';
  if (inv?.event_status === 'completed') return 'Completed';
  return '';
};

const getInventoryStatusCell = (params: GridRenderCellParams<any>) => {
  const error =
    params.value === 'Needs price' || params.value === 'Price Not Shown';

  return h(
    Stack,
    { direction: 'row', sx: { alignItems: 'center', spacing: 1 } },
    [
      error
        ? h(Tooltip, {
            title: 'One or more games are missing price.',
            children: h(ErrorIcon, { color: 'error', fontSize: 'small' }),
          })
        : '',
      h(Typography, { color: 'default', fontSize: 14 }, params.value),
    ],
  );
};

const filter_specs: QuickFilterSpec<
  '2022' | '2023' | LeagueId | QualifyStatus | ConversionStage
>[] = [
  {
    id: 'season',
    label: 'Season',
    optionValues: [
      { label: '2022', value: '2022' },
      { label: '2023', value: '2023' },
    ],
  },
  {
    id: 'league',
    label: 'League',
    optionValues: [
      { label: LeagueId.NFL, value: LeagueId.NFL },
      { label: LeagueId.NBA, value: LeagueId.NBA },
      { label: LeagueId.NHL, value: LeagueId.NHL },
      { label: LeagueId.MLB, value: LeagueId.MLB },
      { label: LeagueId.MLS, value: LeagueId.MLS },
      { label: LeagueId.NCAAB, value: LeagueId.NCAAB },
      { label: LeagueId.NCAAF, value: LeagueId.NCAAF },
    ],
  },
  {
    id: 'qualification',
    label: 'Qualification',
    optionValues: [
      { label: 'Pending', value: QualifyStatus.PENDING },
      { label: 'Qualified', value: QualifyStatus.QUALIFIED },
      { label: 'Unqualified', value: QualifyStatus.NOT_QUALIFIED },
    ],
  },
  // { id: 'is_shown', label: 'Showing?', yesNoAny: true },
  // { id: 'is_priced', label: 'Priced?', yesNoAny: true },
];

const getRoundHeader = (pr: {
  name: string;
  order: number;
  leagues: { league_name: string; league: LeagueId }[];
}) => {
  let leagues = [];
  let visited_leagues = new Set();
  for (const item of pr.leagues) {
    if (!visited_leagues.has(item.league)) {
      leagues.push(item);
      visited_leagues.add(item.league);
    }
  }
  return h(Tooltip, {
    title: h(
      Stack,
      {},
      leagues.map((league_round) =>
        h(
          Box,
          {
            sx: { display: 'inline-flex', alignItems: 'center', gap: 1 },
          },
          [
            h(LeagueIcon, {
              key: league_round.league,
              league: league_round.league as LeagueId,
              fontSize: 'small',
            }),
            `${league_round.league}: ${league_round.league_name}`,
          ],
        ),
      ),
    ),
    placement: 'top',
    children: h(
      Box,
      {
        sx: {
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'center',
          gap: 1,
        },
      },
      [
        h(InfoIcon, { color: 'action', fontSize: 'small' }),
        h(Typography, { sx: { fontSize: 14 } }, pr.name),
      ],
    ),
  });
};

export default function PlayoffPackagesDataGrid({
  rows,
  onDataUpdated,
}: {
  rows: any[];
  onDataUpdated: () => void;
}) {
  const {
    baseData: {
      include_archived,
      include_test_data,
      getPlayoffRoundsForAllLeagues,
    },
  } = useBaseData();

  const [data, setData] = useState(rows);
  const updateCell = (row: any, field: string, value: any) => {
    const new_data = [...data];
    const target_idx = data.findIndex((item) => item.id === row.id);
    new_data[target_idx][field] = value;
    setData(new_data);
    onDataUpdated();
  };
  useEffect(() => {
    setData(rows);
  }, [rows]);

  const playoff_rounds = getPlayoffRoundsForAllLeagues();
  const inventory_status_column = playoff_rounds.map((pr) => ({
    field: pr.name,
    minWidth: 130,
    valueGetter: ({ row }: { row: any }) =>
      row[pr.name]
        ? getInventoryStatus(
            row.is_shown,
            row[pr.name],
            row[`${pr.name}&is_price_shown`],
          )
        : '',
    renderCell: getInventoryStatusCell,
    renderHeader: () => getRoundHeader(pr),
  }));
  const is_initial_priced_column = playoff_rounds.map((pr) =>
    DataGridCustomColumn({
      field: `${pr.name}&is_initial_priced`,
      headerName: pr.name,
      onSaveSucceed: updateCell,
      doSave: api.updatePlayoffPackageRecord,
      api_key_field: `${pr.name}&is_initial_priced`,
      type: 'boolean',
      isHidden: ({ row }) => row[`${pr.name}&is_initial_priced`] === undefined,
    }),
  );
  const is_price_shown_column = playoff_rounds.map((pr) =>
    DataGridCustomColumn({
      field: `${pr.name}&is_price_shown`,
      headerName: pr.name,
      onSaveSucceed: updateCell,
      doSave: api.updatePlayoffPackageRecord,
      api_key_field: `${pr.name}&is_price_shown`,
      type: 'boolean',
      isHidden: ({ row }) => row[`${pr.name}&is_price_shown`] === undefined,
      isDisabled: ({ row }) =>
        !(!!row[`${pr.name}`].is_priced || !!row[`${pr.name}&is_price_shown`]),
    }),
  );
  const is_texted_column = playoff_rounds.map((pr) =>
    DataGridCustomColumn({
      field: `${pr.name}&is_texted`,
      headerName: pr.name,
      onSaveSucceed: updateCell,
      doSave: api.updatePlayoffPackageRecord,
      api_key_field: `${pr.name}&is_texted`,
      type: 'boolean',
      isHidden: ({ row }) => row[`${pr.name}&is_texted`] === undefined,
      isDisabled: ({ row }) => !row[`${pr.name}&is_price_shown`],
    }),
  );
  const payment_status_column = playoff_rounds.map((pr) =>
    DataGridCustomColumn({
      field: `${pr.name}&payment_status`,
      headerName: pr.name,
      onSaveSucceed: updateCell,
      doSave: async (item: any, value: any, round_field?: string) => {
        const ticket_package_id = item.id;
        const [playoff_round_name] = (round_field ?? '').split('&');
        const { leagues } =
          playoff_rounds.find((pr) => pr.name === playoff_round_name) ?? {};
        const { id: playoff_round_id } = leagues
          ? leagues.find(
              (l) => l.league === item.league && l.season === item.season,
            ) ?? { id: null }
          : { id: null };

        if (playoff_round_id)
          await api.updatePlayoffPackageRecordPaymentStatus({
            ticket_package_id,
            playoff_round_id,
            value,
          });
      },
      api_key_field: `${pr.name}&payment_status`,
      minWidth: 170,
      type: 'string',
      options: [
        { label: '', value: 'unknown' },
        { label: 'Pay As We Play', value: 'pay_as_we_play' },
        { label: 'Paid in Full', value: 'pay_in_full' },
        { label: 'Unpaid', value: 'unpaid' },
        { label: 'Paid', value: 'Paid' },
      ],
      isHidden: ({ row }: { row: any }) =>
        !pr.leagues.some((lg) => lg.league === row.league),
    }),
  );
  const is_barcode_entered_column = playoff_rounds.map((pr) => ({
    field: `${pr.name}&is_barcode_entered`,
    headerName: pr.name,
    minWidth: 130,
    valueGetter: ({ row }: { row: any }) =>
      row[pr.name]
        ? row[pr.name].is_barcode_entered
          ? 'All present'
          : 'Any missing'
        : '',
    renderHeader: () => getRoundHeader(pr),
  }));
  const is_marketplace_broadcasted_column = playoff_rounds.map((pr) => ({
    field: `${pr.name}&marketplace_status`,
    headerName: pr.name,
    minWidth: 130,
    valueGetter: ({ row }: { row: any }) => {
      if (!row[pr.name]) return '';
      if (row[pr.name].is_awaiting_broadcast) return 'Not broadcasted';
      return '';
    },
    renderHeader: () => getRoundHeader(pr),
  }));

  const getInventoryStatusColGroup = () => {
    const status_tooltip = [
      `Unshown: ticket package is not shown, applies to all rounds unless “!Needs price” (see below)`,
      'Unpriced: shown tp, unpriced inventory in round, but round is not listable',
      'Priced: shown tp, all inventory in round priced, but round is not listable',
      'Listable: shown, tp all inventory in round is priced, round is listable',
      '!Needs price: shown or unshown tp, unpriced inventory in round and round is listable',
    ];
    return {
      groupId: 'Playoff Inventory Status',
      children: inventory_status_column,
      headerClassName: 'tiq-grid-naming-group',
      renderHeaderGroup: () =>
        h(Tooltip, {
          title: h(
            Stack,
            {},
            status_tooltip.map((st) =>
              h(
                Box,
                {
                  sx: { display: 'inline-flex', alignItems: 'center', gap: 1 },
                },
                [st],
              ),
            ),
          ),
          placement: 'top',
          children: h(
            Box,
            {
              sx: {
                display: 'flex',
                justifyContent: 'between',
                alignItems: 'center',
                gap: 1,
              },
            },
            [
              h(
                Typography,
                { sx: { fontSize: 14, fontWeight: 'bold' } },
                'Playoff Inventory Status',
              ),
              h(InfoIcon, { color: 'action', fontSize: 'small' }),
            ],
          ),
        }),
    };
  };

  return PageDataGrid({
    id: 'playoff-ticket-packages',
    rows: _.filter(
      (r) =>
        (!r.is_archived || include_archived) &&
        (r.is_client || include_test_data),
      data,
    ),
    column_specs: [
      ...column_specs,
      DataGridNoteColumnV2({
        field: 'note',
        valueGetter: ({ row }) => row.note,
        headerName: 'ticket_package',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          });
          updateCell(row, 'note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          });
          updateCell(row, 'has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.client_name}'s ${row.team_name} package (${row.season} ${
            row.is_playoff ? 'playoff' : 'regular'
          } season, sec ${row.section}, row ${row.row}, s ${row.first_seat}-${
            row.last_seat
          })`,
        hasNoteBody: (row) => !!row.has_notes,
        api_key_field: 'id',
      }),
      { field: 'qualification', minWidth: 120 },
      { field: 'is_priced', minWidth: 120 },
      { field: 'is_shown', minWidth: 120 },
      { field: 'is_archived', minWidth: 120 },
      stringDateTime({
        field: 'first_shown',
        minWidth: 120,
      }),
      ...is_barcode_entered_column,
      ...is_marketplace_broadcasted_column,
      ...inventory_status_column,
      ...is_initial_priced_column,
      ...is_price_shown_column,
      ...is_texted_column,
      ...payment_status_column,
    ],
    column_groups: [
      ...column_groups,
      {
        groupId: 'Barcode status',
        children: is_barcode_entered_column,
        headerClassName: 'tiq-grid-naming-group',
      },
      getInventoryStatusColGroup(),
      {
        groupId: 'Initial priced?',
        children: is_initial_priced_column,
        headerClassName: 'tiq-grid-naming-group',
      },
      {
        groupId: 'Show Price',
        children: is_price_shown_column,
        headerClassName: 'tiq-grid-naming-group',
      },
      {
        groupId: 'Is texted?',
        children: is_texted_column,
        headerClassName: 'tiq-grid-naming-group',
      },
      {
        groupId: 'Payment Status',
        children: payment_status_column,
        headerClassName: 'tiq-grid-naming-group',
      },
      {
        groupId: 'Marketplace Issue',
        children: is_marketplace_broadcasted_column,
        headerClassName: 'tiq-grid-naming-group',
      },
    ],
    filter_specs,
    add_button: false,
  });
}
